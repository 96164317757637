import React, { FunctionComponent, useEffect, useState } from 'react';

import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import { listModuleOverrides } from '../services/import-map';
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    bottom: 16px;
    left: 16px;
    z-index: 9999999;
`;

type Props = {
    onClick: () => void;
};

const isOverriden = () => {
    const overrides = listModuleOverrides();
    return overrides && overrides.length > 0;
};

export const DevtoolsButton: FunctionComponent<Props> = ({ onClick }) => {
    const [overriden, setOverriden] = useState(isOverriden());

    useEffect(() => {
        const updateButton = () => {
            setOverriden(isOverriden());
        };
        window.addEventListener('import-map-overrides:change', updateButton);
        return () => {
            window.removeEventListener(
                'import-map-overrides:change',
                updateButton
            );
        };
    }, [setOverriden]);

    return (
        <Container onClick={onClick}>
            <Button
                variant={overriden ? 'default' : 'outline'}
                iconSrc="HamburgerMenu"
                data-test-id="DevtoolsBtn"
            />
        </Container>
    );
};
