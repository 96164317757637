import { ImportMapImports, addModuleOverride } from './import-map';
import { Layout, Route, overrideLayout, pushRoute } from './layout';

const removeParamsFromUrl = (...params: string[]) => {
    const path = window.location.pathname;
    const qs = params
        .reduce((prev, curr) => {
            prev.delete(curr);
            return prev;
        }, new URLSearchParams(window.location.search))
        .toString();
    window.history.pushState(null, '', !!qs ? `${path}?${qs}` : path);
};

export const addImportMapQueryOverride = (params: URLSearchParams) => {
    const importMapQuery = params.get('mx-importmap');

    if (importMapQuery) {
        const importOverrides: ImportMapImports = JSON.parse(
            atob(importMapQuery)
        );
        Object.entries(importOverrides).forEach(([appName, overrideUrl]) => {
            addModuleOverride(appName, overrideUrl);
        });
    }
    removeParamsFromUrl('mx-importmap');
};

export const addLayoutQueryOverride = (params: URLSearchParams) => {
    const layoutQuery = params.get('mx-layout');
    if (layoutQuery) {
        const layout: Layout = JSON.parse(atob(layoutQuery));
        if (layout?.routes?.length) {
            overrideLayout(layout);
        }
    }
    removeParamsFromUrl('mx-layout');
};

export const addLayoutRoutesQueryOverride = (params: URLSearchParams) => {
    const layoutRoutesQuery = params.get('mx-layout-routes');
    if (layoutRoutesQuery) {
        const routes: Route[] = JSON.parse(atob(layoutRoutesQuery));
        routes.forEach((r) => pushRoute(r));
    }
    removeParamsFromUrl('mx-layout-routes');
};
