import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import {
    getLayout,
    overrideLayout,
    resetLayout,
} from '../../../services/layout';

import { Footer } from './footer';
import { ModalContentBody } from './body';
import MonacoEditor from 'react-monaco-editor';
import { useToast } from '../../../hooks/use-toast';

const prettyLayout = () => JSON.stringify(getLayout(), null, '  ');

export const LayoutEditor: FunctionComponent = () => {
    // due to complex html structure of the monaco could not repaint editor using vanilla js
    const [, _] = useState<number>();
    const rerender = useCallback(() => _(Math.random()), []);
    const layoutRef = useRef(prettyLayout());
    const { setToastMessage } = useToast();

    const handleEdit = useCallback(
        (layout) => {
            layoutRef.current = layout;
        },
        [layoutRef]
    );

    const handleCancel = useCallback(() => {
        rerender();
    }, [rerender]);

    const handleDefault = useCallback(() => {
        handleCancel();
        resetLayout();
        setToastMessage('Layout was reset.');
    }, [handleCancel, setToastMessage]);

    const handleApply = useCallback(() => {
        overrideLayout(JSON.parse(layoutRef.current));
        setToastMessage('Layout was overridden.');
    }, [layoutRef, setToastMessage]);

    return (
        <>
            <ModalContentBody data-test-id="LayoutEditor">
                <MonacoEditor
                    value={prettyLayout()}
                    language="json"
                    options={{
                        minimap: {
                            enabled: false,
                        },
                    }}
                    onChange={handleEdit}
                />
            </ModalContentBody>
            <Footer
                buttons={[
                    {
                        children: 'Default',
                        onClick: handleDefault,
                        variant: 'outline',
                    },
                    {
                        children: 'Cancel',
                        onClick: handleCancel,
                        variant: 'outline',
                    },
                    {
                        children: 'Apply',
                        onClick: handleApply,
                        variant: 'default',
                    },
                ]}
            />
        </>
    );
};
