type CustomProps = {
    [key: string]: unknown;
};

type Application = {
    type: 'application';
    name: string;
    props?: CustomProps;
};

export type Route = {
    type: 'route';
    path: string;
    routes: Application[];
    props?: CustomProps;
};

export type Layout = {
    routes: Route[];
    containerEl: string;
};

export const getLayout = (): Layout =>
    JSON.parse(sessionStorage.getItem('mx-layout')!);

export const listRoutes = (): Route[] => getLayout().routes;

export const overrideLayout = (layout: Layout): void => {
    sessionStorage.setItem('mx-layout', JSON.stringify(layout));
};

export const resetLayout = (): void => sessionStorage.removeItem('mx-layout');

export const addRoute = (moduleName: string, path: string): void => {
    const layout = getLayout();
    layout.routes.push({
        type: 'route',
        routes: [
            {
                type: 'application',
                name: moduleName,
            },
        ],
        path,
    });
    overrideLayout(layout);
};

export const pushRoute = (route: Route): void => {
    const layout = getLayout();
    layout.routes.push(route);
    overrideLayout(layout);
};
