import {
    addImportMapQueryOverride,
    addLayoutQueryOverride,
} from './services/query-overrides';
import singleSpaReact, { ReactAppOrParcel } from 'single-spa-react';

import React from 'react';
import ReactDOM from 'react-dom';
import { Root } from './root';

const lifeCycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary() {
        return <></>;
    },
});

export const mount: ReactAppOrParcel<{}>['mount'] = (props) => {
    const params = new URLSearchParams(window.location.search);
    addImportMapQueryOverride(params);
    addLayoutQueryOverride(params);
    return lifeCycles.mount(props);
};

export const { bootstrap, unmount } = lifeCycles;
