import React, { FunctionComponent, useState } from 'react';

import { AddAppForm } from './content/add-app-form';
import { AppsForm } from './content/apps-form';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import { FindModuleForm } from './content/find-module-form';
import { LayoutEditor } from './content/layout-editor';
import { ShareForm } from './content/share-form';
import { mediaMaxWidth } from '../../constants';
import styled from 'styled-components';

const Container = styled.div`
    margin-top: 24px;
    padding-top: 24px;
`;

const Content = styled.div`
    z-index: 100;
    height: 380px;
    display: flex;
    flex-flow: column;
`;

const Tabs = styled.div`
    position: absolute;
    top: 40px;
    display: inline-flex;
    z-index: 101;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
`;

const Tab = styled(Button)`
    width: 120px;
    border-radius: 0;
    border-right-width: 0;
    &:first-child {
        border-top-left-radius: 10rem;
        border-bottom-left-radius: 10rem;
    }
    &:last-child {
        border-top-right-radius: 10rem;
        border-bottom-right-radius: 10rem;
        border-right-width: 2px;
    }

    @media (max-width: ${mediaMaxWidth}px) {
        width: 70px;
    }
`;

enum TabId {
    AddApp = 'add-app',
    Apps = 'apps',
    Layout = 'layout',
    Modules = 'modules',
    Share = 'share',
}

const tabs = [
    {
        id: TabId.Modules,
        name: 'modules',
    },
    {
        id: TabId.Apps,
        name: 'apps',
    },
    {
        id: TabId.AddApp,
        name: 'add app',
    },
    {
        id: TabId.Layout,
        name: 'layout',
    },
    {
        id: TabId.Share,
        name: 'share',
    },
];

export const Body: FunctionComponent = () => {
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    const contentMap: Record<TabId, JSX.Element> = {
        [TabId.Apps]: <AppsForm />,
        [TabId.AddApp]: <AddAppForm />,
        [TabId.Modules]: <FindModuleForm />,
        [TabId.Layout]: <LayoutEditor />,
        [TabId.Share]: <ShareForm />,
    };
    const getTabVariant = (tab: string) =>
        activeTab === tab ? 'default' : 'outline';

    return (
        <Container>
            <Tabs>
                {tabs.map((t, i) => (
                    <Tab
                        key={i}
                        variant={getTabVariant(t.id)}
                        size="small"
                        onClick={() => setActiveTab(t.id)}
                        children={t.name}
                        data-test-id={`${t.id}BodyButton`}
                    />
                ))}
            </Tabs>
            <Content>{contentMap[activeTab]}</Content>
        </Container>
    );
};
