import React, { FunctionComponent } from 'react';

import { Body } from './body';
import Draggable from 'react-draggable';
import { Header } from './header';
import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import { mediaMaxWidth } from '../../constants';
import styled from 'styled-components';

const Modal = styled.div`
    background-color: ${colors.neutralBrightest};
    border-radius: 2px;
    width: 800px;
    position: fixed;
    bottom: 80px;
    left: 16px;
    border: solid 1px ${colors.neutralBrighter};

    @media (max-width: ${mediaMaxWidth}px) {
        flex-direction: column;
        left: 0;
        right: 0;
        width: 100vw;
    }
`;

export const Devtools: FunctionComponent = () => {
    const handleId = 'mx-devtools-handle';
    return (
        <Draggable handle={`#${handleId}`}>
            <Modal>
                <Header id={handleId} />
                <Body />
            </Modal>
        </Draggable>
    );
};
