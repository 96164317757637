declare global {
    interface Window {
        importMapOverrides: {
            addOverride: (name: string, url: string) => void;
            enableOverride: (name: string) => void;
            getCurrentPageMap: () => Promise<ImportMap>;
            getOverrideMap: (includeDisabled: boolean) => ImportMap;
            resetOverrides: () => void;
            disableOverride: (name: string) => void;
            isDisabled: (name: string) => boolean;
        };
    }
}

export type ImportMapImports = {
    [key: string]: string;
};

export type ImportMap = {
    imports: ImportMapImports;
};

export type Module = {
    name: string;
    url: string;
    overridden: boolean;
};

let cache: Module[] | undefined = undefined;

const listDefaultModules = async (): Promise<Module[]> => {
    if (!cache) {
        const map = await window.importMapOverrides.getCurrentPageMap();
        cache = Object.entries(map.imports).map(([k, v]) => ({
            name: k,
            url: v,
            overridden: false,
        }));
    }
    return cache;
};

export const listModuleOverrides = (
    includeDisabled: boolean = false
): Module[] => {
    const map = window.importMapOverrides.getOverrideMap(includeDisabled);
    return Object.entries(map.imports).map(([k, v]) => ({
        name: k,
        url: v,
        overridden: true,
    }));
};

export const listModules = async (
    includeDisabled: boolean = true
): Promise<Module[]> => {
    const modules = await listDefaultModules();
    const overrides = listModuleOverrides(includeDisabled);

    const diff = modules.filter(
        (m) => !overrides.find((o) => o.name === m.name)
    );

    return [...diff, ...overrides];
};

export const addModuleOverride = (name: string, url: string): void => {
    window.importMapOverrides.addOverride(name, url);
    window.importMapOverrides.enableOverride(name);
};

export const removeModuleOverrides = (): void =>
    window.importMapOverrides.resetOverrides();

export const enableModuleOverride = (name: string): void =>
    window.importMapOverrides.enableOverride(name);

export const disableModuleOverride = (name: string): void =>
    window.importMapOverrides.disableOverride(name);

export const isDisabledModuleOverride = (name: string): boolean =>
    window.importMapOverrides.isDisabled(name);
