import React, { FunctionComponent, useCallback, useState } from 'react';
import { Switch, Text, colors } from '@soluto-private/mx-asurion-ui-react-v3';

import { Route } from '../../../services/layout';
import styled from 'styled-components';

const Container = styled.div<{ overridden?: boolean }>`
    display: flex;
    justify-content: space-between;
    margin: 4px 0;
    padding: 6px 0;
    cursor: pointer;
    border-radius: 2px;
    color: ${colors.white};
    background-color: ${(props) =>
        props.overridden ? colors.primaryDeep : colors.neutralBright};
`;

const LayoutName = styled.div`
    padding-left: 8px;
    width: 100%;
`;

type Props = {
    layout: Route;
    onClick: () => any;
};

export const Layout: FunctionComponent<Props> = ({ layout, onClick }) => {
    const [enabled, setEnabled] = useState(false);

    const handleOverrideSwitch = useCallback(() => {
        setEnabled(!enabled);
        onClick();
    }, [setEnabled, onClick, enabled]);

    return (
        <Container overridden={enabled}>
            <LayoutName onClick={onClick}>
                <Text>{layout.path}</Text>
            </LayoutName>
            <Switch
                onClick={handleOverrideSwitch}
                label=""
                checked={enabled}
                labelPosition="right"
                size="inline"
            />
        </Container>
    );
};
