import React, {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    createContext,
    useState,
} from 'react';

import { ToastContext } from '../components/toast';

type Props = {
    setToastMessage: Dispatch<SetStateAction<string>>;
};

export const SetterContext = createContext<Props>({
    setToastMessage: () => {},
});

export const SetterProvider: FunctionComponent = ({ children }) => {
    const [toastMessage, setToastMessage] = useState('');
    return (
        <SetterContext.Provider value={{ setToastMessage }}>
            <ToastContext.Provider value={{ message: toastMessage }}>
                {children}
            </ToastContext.Provider>
        </SetterContext.Provider>
    );
};
