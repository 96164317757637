import React, { FunctionComponent } from 'react';
import { Text, colors } from '@soluto-private/mx-asurion-ui-react-v3';

import styled from 'styled-components';

const Container = styled.div`
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${colors.neutralBrighter};
`;

type Props = {
    id: string;
};

export const Header: FunctionComponent<Props> = ({ id }) => (
    <Container id={id} data-test-id="Header">
        <Text size={2} color={colors.primaryDeep}>
            devtools
        </Text>
    </Container>
);
