import { Application, listApps } from '../../../services/application';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Tag, Text, colors } from '@soluto-private/mx-asurion-ui-react-v3';

import { ModalContentBody } from './body';
import styled from 'styled-components';

const Apps = styled.div`
    overflow: auto;
    max-height: 80%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
`;

const App = styled.div`
    margin: 8px 16px;
    padding: 8px;
    cursor: pointer;
    place-content: center;
    border-radius: 2px;
    border: 1px solid ${colors.neutralBrighter};
`;

const Route = styled(Tag)`
    margin-top: 3px;
`;

export const AppsForm: FunctionComponent = () => {
    const [apps, setApps] = useState<Application[]>([]);

    useEffect(() => {
        const loadApps = async () => {
            const apps = await listApps();
            setApps(apps);
        };
        loadApps();
    }, []);

    return (
        <>
            <ModalContentBody data-test-id="AppsForm">
                <Apps>
                    {apps.map(({ module, name, routes }, i) => (
                        <App key={i}>
                            <div>
                                <Text size={3}>{name}</Text>
                            </div>
                            <div>
                                <Text size={1} weight="feather">
                                    {module.name}: {module.url}
                                </Text>
                            </div>
                            {routes.map((r, i) => (
                                <Route key={i} text={r}></Route>
                            ))}
                        </App>
                    ))}
                </Apps>
            </ModalContentBody>
        </>
    );
};
