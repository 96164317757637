import styled from 'styled-components';

export const ModalContentBody = styled.div`
    data-test-id: ModalContentBody;
    flex: 1 1 auto;
`;

export const ModalContentFormBody = styled.div`
    data-test-id: ModalContentFormBody;
    flex: 1 1 auto;
    padding: 0px 32px;
    position: relative;
`;
