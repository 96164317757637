import { Button, ButtonProps } from '@soluto-private/mx-asurion-ui-react-v3';
import React, { FunctionComponent } from 'react';

import { mediaMaxWidth } from '../../../constants';
import styled from 'styled-components';

const Container = styled.div`
    padding: 8px 16px;
    display: flex;
    justify-content: flex-end;
    align-items: end;

    @media (max-width: ${mediaMaxWidth}px) {
        justify-content: center;
    }
`;

const FooterButton = styled(Button)`
    width: 100px;
    margin-left: 6px;
`;

type Props = {
    buttons: ButtonProps[];
};

export const Footer: FunctionComponent<Props> = ({ buttons }) => (
    <Container data-test-id="Footer">
        {buttons.map((props, i) => {
            const id = `devtools-${`${props.children}`
                .toLowerCase()
                .replace(/\s/g, '-')}`;
            return (
                <FooterButton
                    key={i}
                    id={id}
                    size="small"
                    data-test-id={`${props.children}Btn`}
                    {...props}
                />
            );
        })}
    </Container>
);
