import {
    Module as ModuleType,
    disableModuleOverride,
    enableModuleOverride,
    isDisabledModuleOverride,
} from '../../../services/import-map';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Switch, Text, colors } from '@soluto-private/mx-asurion-ui-react-v3';

import styled from 'styled-components';
import { useToast } from '../../../hooks/use-toast';

const Container = styled.div<{ overridden?: boolean }>`
    display: flex;
    justify-content: space-between;
    margin: 4px 0;
    padding: 6px 0;
    cursor: pointer;
    border-radius: 2px;
    color: ${colors.white};
    background-color: ${(props) =>
        props.overridden ? colors.primaryDeep : colors.neutralBright};
`;

const ModuleName = styled.div`
    padding-left: 8px;
    width: 100%;
`;

type Props = {
    module: ModuleType;
    onClick: () => any;
};

export const Module: FunctionComponent<Props> = ({ module, onClick }) => {
    const [enabled, setEnabled] = useState(
        () => !isDisabledModuleOverride(module.name) && module.overridden
    );
    const { setToastMessage } = useToast();

    const handleOverrideSwitch = useCallback(() => {
        if (enabled) {
            disableModuleOverride(module.name);
            setToastMessage(`${module.name} override was disabled.`);
        } else {
            enableModuleOverride(module.name);
            setToastMessage(`${module.name} override was enabled.`);
        }
        setEnabled(!enabled);
    }, [module, setToastMessage, setEnabled, enabled]);

    return (
        <Container overridden={module.overridden} data-test-id="Module">
            <ModuleName onClick={onClick}>
                <Text>{module.name}</Text>
            </ModuleName>
            <Switch
                onClick={handleOverrideSwitch}
                label=""
                disabled={!module.overridden}
                checked={enabled}
                labelPosition="right"
                size="inline"
                data-test-id="ModuleSwitch"
            />
        </Container>
    );
};
