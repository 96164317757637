import * as JsSearch from 'js-search';

import {
    Module as ModuleType,
    addModuleOverride,
    listModules,
    removeModuleOverrides,
} from '../../../services/import-map';
import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { Footer } from './footer';
import { ModalContentFormBody } from './body';
import { Module } from './module';
import { TextField } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { useToast } from '../../../hooks/use-toast';

const HideScroll = styled.div`
    overflow: hidden;
    position: relative;
    height: 45%;
`;

const Modules = styled.div`
    position: absolute;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    left: 0;
    right: -17px;
`;

const engine = new JsSearch.Search('name');
engine.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
engine.addIndex('name');

const defaultSelection = {
    url: '',
    name: '',
};

export const FindModuleForm: FunctionComponent = () => {
    const [selected, setSelected] = useState(defaultSelection);
    const [cancelDisabled, setCancelDisabled] = useState(true);
    const [applyDisabled, setApplyDisabled] = useState(true);
    const [allModules, setAllModules] = useState<ModuleType[]>([]);
    const [filteredModules, setFilteredModules] = useState<ModuleType[]>([]);

    const { setToastMessage } = useToast();

    const loadModules = useCallback(async () => {
        const modules = await listModules();
        modules.sort(({ overridden }) => (overridden ? -1 : 1));
        setAllModules(modules);
    }, []);

    useEffect(() => {
        window.addEventListener('import-map-overrides:change', loadModules);
        return () => {
            window.removeEventListener(
                'import-map-overrides:change',
                loadModules
            );
        };
    }, [loadModules]);

    useEffect(() => {
        const cancelDisabled: boolean = selected === defaultSelection;
        const applyDisabled: boolean =
            selected?.name?.length === 0 || selected?.url?.length === 0;

        setCancelDisabled(cancelDisabled);
        setApplyDisabled(applyDisabled);
    }, [selected]);

    useEffect(() => {
        loadModules();
    }, [loadModules]);

    useEffect(() => {
        setFilteredModules(allModules);
    }, [allModules]);

    const search = useCallback(
        (query) => {
            engine.addDocuments(filteredModules);
            query === ''
                ? setFilteredModules(allModules)
                : setFilteredModules(engine.search(query) as ModuleType[]);
        },
        [filteredModules, allModules]
    );

    const handleSearch = useCallback(
        ({ target }) => {
            let updatedValue = {};
            updatedValue = { name: target.value };
            setSelected((s) => ({ ...s, ...updatedValue }));
            search(target.value);
        },
        [search]
    );

    const handleCancel = useCallback(() => {
        setSelected(defaultSelection);
        setFilteredModules(allModules);
    }, [allModules]);

    const handleDefault = useCallback(() => {
        handleCancel();
        removeModuleOverrides();
        setToastMessage('All module overrides were removed.');
    }, [handleCancel, setToastMessage]);

    const handleApply = useCallback(() => {
        addModuleOverride(selected.name, selected.url);
        handleCancel();
        setToastMessage(`Module [${selected.name}] was overridden.`);
    }, [selected, handleCancel, setToastMessage]);

    const handleModuleUrlChange = useCallback(({ target }) => {
        let updatedValue = {};
        updatedValue = { url: target.value };
        setSelected((s) => ({ ...s, ...updatedValue }));
    }, []);

    return (
        <>
            <ModalContentFormBody data-test-id="FindModuleForm">
                <TextField
                    value={selected.name}
                    onChange={handleSearch}
                    label="Module name"
                    helperText="Search for SystemJS module name. ex: @soluto-private/mx-app"
                    data-test-id="FindModule_ModuleNameTextField"
                />
                <TextField
                    value={selected.url}
                    onChange={handleModuleUrlChange}
                    label="Override module URL"
                    helperText="New URL to SystemJS module. ex: /mx-app/app-bundle.js"
                    data-test-id="FindModule_OverrideModuleTextField"
                />
                <HideScroll>
                    <Modules>
                        {filteredModules.map((module) => (
                            <Module
                                key={module.name}
                                onClick={() => setSelected(module)}
                                module={module}
                            />
                        ))}
                    </Modules>
                </HideScroll>
            </ModalContentFormBody>
            <Footer
                buttons={[
                    {
                        children: 'Reset All',
                        onClick: handleDefault,
                        variant: 'outline',
                    },
                    {
                        children: 'Cancel',
                        onClick: handleCancel,
                        disabled: cancelDisabled,
                        variant: 'outline',
                    },
                    {
                        children: 'Apply',
                        onClick: handleApply,
                        disabled: applyDisabled,
                        variant: 'default',
                    },
                ]}
            />
        </>
    );
};
