import React, {
    FunctionComponent,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { Route, getLayout } from '../../../services/layout';

import { Footer } from './footer';
import { Layout } from './layout';
import { ModalContentFormBody } from './body';
import { TextField } from '@soluto-private/mx-asurion-ui-react-v3';
import { listModuleOverrides } from '../../../services/import-map';
import styled from 'styled-components';
import { useToast } from '../../../hooks/use-toast';

const HideScroll = styled.div`
    overflow: hidden;
    position: relative;
    height: 70%;
`;

const Layouts = styled.div`
    position: absolute;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0px;
`;

const InstructionText = styled.div`
    width: 100%;
`;

const appendSearchParams = (params: Record<string, string>) =>
    Object.entries(params).reduce((url, [param, value]) => {
        url.searchParams.set(param, value);
        return url;
    }, new URL(window.location.href)).href;

export const ShareForm: FunctionComponent = () => {
    const localLayout = getLayout();
    const [selected, setSelected] = useState<Route[]>([]);
    const { setToastMessage } = useToast();

    const link = useMemo(() => {
        let layout = getLayout();
        layout.routes = selected;

        const overrides: Record<string, string> = {};
        listModuleOverrides().forEach(
            ({ name, url }) => (overrides[name] = url)
        );
        return appendSearchParams({
            'mx-layout': btoa(JSON.stringify(layout)),
            'mx-importmap': btoa(JSON.stringify(overrides)),
        });
    }, [selected]);

    const addRemoveLayout = (layout: Route) => {
        setSelected((oldArray) =>
            oldArray.findIndex((element) => element.path === layout.path) >= 0
                ? oldArray.filter((element) => element.path !== layout.path)
                : [...oldArray, layout]
        );
    };

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(link);
        setToastMessage('Shareable link was copied to the clipboard.');
    }, [link, setToastMessage]);

    return (
        <>
            <ModalContentFormBody data-test-id="ShareForm">
                <TextField
                    value={link}
                    label="Shareable link"
                    helperText="Use this link to share your modules and layout overrides."
                    affixRight="Copy"
                    affixRightClickable={true}
                    onAffixRightClick={handleCopy}
                    data-test-id="ShareableLinkTextField"
                />
                <InstructionText>
                    Select layout paths below to include in URL (module
                    overrides are always included)
                </InstructionText>
                <HideScroll>
                    <Layouts>
                        {localLayout.routes.map((layout) => (
                            <Layout
                                key={layout.path}
                                onClick={() => addRemoveLayout(layout)}
                                layout={layout}
                            />
                        ))}
                    </Layouts>
                </HideScroll>
            </ModalContentFormBody>
            <Footer
                buttons={[
                    {
                        children: 'Copy',
                        onClick: handleCopy,
                        variant: 'outline',
                    },
                ]}
            />
        </>
    );
};
