import React, { FunctionComponent, useCallback, useState } from 'react';

import { Footer } from './footer';
import { ModalContentFormBody } from './body';
import { TextField } from '@soluto-private/mx-asurion-ui-react-v3';
import { addApp } from '../../../services/application';
import { useToast } from '../../../hooks/use-toast';

export const AddAppForm: FunctionComponent = () => {
    const [moduleName, setModelName] = useState('');
    const [moduleUrl, setModelUrl] = useState('');
    const [appRoute, setAppRoute] = useState('');
    const handleCancel = useCallback(() => {
        setModelName('');
        setModelUrl('');
        setAppRoute('');
    }, [setModelName, setModelUrl, setAppRoute]);

    const { setToastMessage } = useToast();

    const handleAdd = useCallback(() => {
        addApp(moduleName, moduleUrl, appRoute);
        handleCancel();
        setToastMessage(
            `[${moduleName}] app was added and served via [${appRoute}] route.`
        );
    }, [appRoute, moduleName, moduleUrl, handleCancel, setToastMessage]);

    const handleModuleNameChange = useCallback(
        ({ target }) => {
            setModelName(target.value);
        },
        [setModelName]
    );

    const handleModuleUrlChange = useCallback(
        ({ target }) => {
            setModelUrl(target.value);
        },
        [setModelUrl]
    );

    const handleAppRouteChange = useCallback(
        ({ target }) => {
            setAppRoute(target.value);
        },
        [setAppRoute]
    );

    return (
        <>
            <ModalContentFormBody data-test-id="AddAppForm">
                <TextField
                    value={moduleName}
                    onChange={handleModuleNameChange}
                    label="Module name"
                    helperText="SystemJS module name. ex: @soluto-private/mx-app"
                    data-test-id="AddAppModuleNameTextField"
                />
                <TextField
                    value={moduleUrl}
                    onChange={handleModuleUrlChange}
                    label="Module URL"
                    helperText="URL to SystemJS module. ex: /mx-app/app-bundle.js"
                    data-test-id="AddAppModuleURLTextField"
                />
                <TextField
                    value={appRoute}
                    onChange={handleAppRouteChange}
                    label="App route"
                    helperText="The app will be mounted under route specified here. ex: /app"
                    data-test-id="AddAppRouteTextField"
                />
            </ModalContentFormBody>
            <Footer
                buttons={[
                    {
                        children: 'Cancel',
                        onClick: handleCancel,
                        variant: 'outline',
                    },
                    {
                        children: 'Add',
                        onClick: handleAdd,
                        variant: 'default',
                    },
                ]}
            />
        </>
    );
};
