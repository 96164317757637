import React, { FunctionComponent, useState } from 'react';

import { Devtools } from './components/modal';
import { DevtoolsButton } from './components/devtools-button';
import { SetterProvider } from './providers/setter';
import { Toast } from './components/toast';

const toastOpenThreshold = 3;

export const Root: FunctionComponent = () => {
    const [open, setOpen] = useState(false);

    return (
        <SetterProvider>
            <DevtoolsButton onClick={() => setOpen((prev) => !prev)} />
            {open && <Devtools />}
            <Toast openThreshold={toastOpenThreshold} />
        </SetterProvider>
    );
};
